import React, {useMemo, useState} from 'react';
import {CartItemDataHook} from '../../CartItem';
import {Counter, CounterSize} from 'wix-ui-tpa/dist/src/components/Counter';
import {debounce} from '../../../../../../../../domain/lib/debounce';
import {
  getMaxItemQuantity,
  getIsQuantityErrorNeeded,
  getIsOutOfStock,
} from '../../../../../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../../ControllerContext';
import {ICartItem} from '../../../../../../../../types/app.types';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {classes} from './Quantity.st.css';
import {Popover} from 'wix-ui-tpa/Popover';

export const Quantity = ({item}: {item: ICartItem}) => {
  const {t} = useTranslation();
  const {updateItemQuantity} = useControllerProps().cartStore;
  const debouncedUpdateItemQuantity = useMemo(() => debounce(updateItemQuantity), [updateItemQuantity]);
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [hasQuantityError, sethasQuantityError] = useState<boolean>(false);
  const handleChange = (value: string) => {
    const isQuantityErrorNeeded = getIsQuantityErrorNeeded(item, +value);
    if (isQuantityErrorNeeded) {
      triggerQuantityErrorIfNeeded();
      return;
    }
    setQuantity(+value);
    return debouncedUpdateItemQuantity(item.cartItemId, +value, item.product.id);
  };

  const triggerQuantityErrorIfNeeded = () => {
    sethasQuantityError(true);
    setTimeout(() => {
      sethasQuantityError(false);
    }, 2e3);
  };

  return (
    <Popover
      data-hook={CartItemDataHook.QuantityErrorTooltip}
      className={classes.popover}
      width={165}
      shown={hasQuantityError}
      placement="top">
      <Popover.Element>
        <Counter
          disabled={getIsOutOfStock(item)}
          className={classes.quantity}
          size={CounterSize.xSmall}
          error={hasQuantityError}
          value={quantity}
          step={1}
          data-hook={CartItemDataHook.Quantity}
          onChange={handleChange}
          incrementAriaLabel={t('cart.sr.addQty')}
          decrementAriaLabel={t('cart.sr.removeQty')}
          inputAriaLabel={t('cart.sr.chooseQty')}
          aria-label={t('cart.sr.quantity')}
          min={1}
          max={getMaxItemQuantity(item)}
        />
      </Popover.Element>
      <Popover.Content>
        <span role="alert">
          {t('cart.errors.item_quantity_exceed_inventory', {
            itemsAmount: getMaxItemQuantity(item),
          } as object)}
        </span>
      </Popover.Content>
    </Popover>
  );
};
